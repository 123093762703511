import api from "../main/main.service";

interface Login {
  email: string;
  password: string;
}

export interface LoginResponse {
  data: {
    auth?: boolean;
    id?: number;
    token?: string;
    msg?: string;
    permissions?: any;
  };
  status: number;
}

export default class LoginService {
  constructor() {}

  login = async (body: Login) => {
    return await api.post("/login", body);
  };

  login_ad = async (body: Login) => {
    return await api.post("/login_ad", body);
  };
}
