import React, { createContext, useState, useEffect } from 'react';
import LoginService from '../api/Login';
import api from '../api/main/main.service';
import axios from 'axios';

const initialState = {
  isLoggedIn: false,
  user: '',
  userId: '',
  token: '',
  module: '',
  permissions: '',
  isPermission: (e) => {},
  companyId: ''
};

const AuthContext = createContext({ ...initialState, login: (e) => Promise, logout: () => {} });

export const AuthProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(null);
  const [user, setUser] = useState(localStorage.getItem('user'));
  const [userId, setUserId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [token, setToken] = useState(null);
  const [module, setModule] = useState(null);
  const loginService = new LoginService();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      setUser(JSON.parse(storedUser));
      setToken(JSON.parse(storedUser).token);
      setUserId(JSON.parse(storedUser).id);
      setCompanyId(JSON.parse(storedUser).id_company);
      setPermissions(JSON.parse(storedUser).profile_permission);
    }
  }, []); 

  const login = (credentials) => {
    const response = new Promise((resolve, reject) => {
      loginService
        .login({
          email: credentials.email,
          password: credentials.password
        })
        .then((res) => {
          if (res.status === 200) {
            setUser(res.data);
            setToken(JSON.stringify(res.data.token));
            setUserId(JSON.stringify(res.data.id));
            setCompanyId(parseInt(res.data.id_company));
            setPermissions(res.data.profile_permission);
            localStorage.setItem('profile_permission', JSON.stringify(res.data.profile_permission));
            localStorage.setItem('token', JSON.stringify(res.data.token));
            localStorage.setItem('userId', JSON.stringify(res.data.id));
            localStorage.setItem('user', JSON.stringify(res.data));
            api.defaults.headers.common['x-access-token'] = res.data.token;
            axios.defaults.headers.common['x-access-token'] = res.data.token;
          }
          return resolve(res);
        })
        .catch((e) => {
          return reject(e);
        });
    });

    return response;
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    setUserId(null);
    setModule(null);
    setCompanyId(null);
    setPermissions(null);
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('user');
    localStorage.removeItem('profile_permission')
    api.defaults.headers.common['x-access-token'] = '';
  };

  const isPermission = (keys) => {
    const permissions = JSON.parse(localStorage.getItem('profile_permission'));
  
    if (!Array.isArray(keys)) {
      keys = [keys]; // converte para um array se for uma string
    }
  
    if (permissions) {
      //console.log("keys.some(key => permissions.some(per => per.key === key))", keys.some(key => permissions.some(per => per.key === key)))
      //console.log("keys", keys)
      return keys.some(key => permissions.some(per => per.key === key));
    } else {
      return false; // ou qualquer outro comportamento padrão caso não haja permissões no local storage
    }
  };

  const Module = (moduleSelected) => {
    setModule(moduleSelected);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: Boolean(user),
        user,
        userId,
        token,
        login,
        logout,
        Module,
        module,
        permissions,
        isPermission,
        companyId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
