import { useContext } from 'react';

import AuthContext from '../contexts/AuthContext';
import FirebaseContext from '../contexts/FirebaseContext';
//import JWTContext from '../contexts/JWTContext';
//import Auth0Context from '../contexts/Auth0Context';

const useAuth = () => useContext(AuthContext);

export default useAuth;
