import { FaBuilding, FaShippingFast } from "react-icons/fa";
import { BiPackage } from "react-icons/bi";
import { BsKanban, BsFillGearFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import useAuth from '../src/hooks/useAuth';
import { HiMiniUserGroup } from "react-icons/hi2";

const MenuItems = () => {
  const { isPermission, permissions } = useAuth();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    //console.log("permissions", permissions)
    setMenuItems(
      {
        
        items: [
          {
            id: 'system',
            title: 'Sistema',
            type: 'group',
            show: isPermission(['ready_oportunit',
            'edit_oportunit',
            'include_oportunit',
            'remove_oportunit']),
            icon: <BsKanban />,
            children: [
              {
                id: 'system',
                title: 'Oportunidades',
                type: 'item',
                show: true,
                icon_string: false,
                icon: <BsKanban />,
                url: '/kanbans',
                classes: 'nav-item',
                target: false,
                breadcrumbs: false
              }
            ]
          },
          /*{
            id: 'system',
            title: 'Sistema',
            type: 'group',
            icon: 'icon-group',
            show: isPermission(['ready_oportunit',
              'edit_oportunit',
              'include_oportunit',
              'remove_oportunit']),
            children: [
              {
                id: 'task-system',
                title: 'Oportunidades',
                type: 'collapse',
                icon_string: false,
                icon: <BsKanban />,
                show: isPermission(['ready_oportunit',
                'edit_oportunit',
                'include_oportunit',
                'remove_oportunit']),
                children: [
                  {
                    id: 'kanban',
                    title: 'Cadastros',
                    type: 'item',
                    url: '/kanbans',
                    show: isPermission(['ready_oportunit',
                    'edit_oportunit',
                    'include_oportunit',
                    'remove_oportunit']),
                  }
                ]
              },
            ]
          },*/
          {
            id: 'clients',
            title: 'Clientes',
            type: 'group',
            show: isPermission(['ready_client',
              'remove_client',
              'include_client',
              'edit_client']),
            icon: 'feather icon-group',
            children: [
              {
                id: 'clients',
                title: 'Clientes',
                type: 'item',
                show: isPermission(['ready_client',
                'remove_client',
                'include_client',
                'edit_client']),
                icon_string: false,
                icon: <HiMiniUserGroup />,
                url: '/clients',
                classes: 'nav-item',
                target: false,
                breadcrumbs: false
              }
            ]
          },
          /*{
            id: 'clients',
            title: 'Clientes',
            type: 'group',
            icon: 'feather icon-group',
            show: isPermission(['ready_client',
              'remove_client',
              'include_client',
              'edit_client']),
            children: [
              {
                id: 'clients',
                title: 'Clientes',
                type: 'collapse',
                icon: 'feather icon-users',
                show: isPermission(['ready_client',
                'remove_client',
                'include_client',
                'edit_client']),
                children: [
                  {
                    id: 'clients',
                    title: 'Cadastros',
                    type: 'item',
                    url: '/clients',
                    show: isPermission(['ready_client',
                    'remove_client',
                    'include_client',
                    'edit_client']),
                  }
                ]
              },
            ]
          },*/
          {
            id: 'suppliers',
            title: 'Fornecedores',
            type: 'group',
            show: isPermission([
              'ready_supplier',
              'edit_supplier',
              'include_supplier',
              'remove_supplier',
            ]),
            icon: 'feather icon-group',
            children: [
              {
                id: 'suppliers',
                title: 'Fornecedores',
                type: 'item',
                show: isPermission([
                  'ready_supplier',
                  'edit_supplier',
                  'include_supplier',
                  'remove_supplier',
                ]),
                icon_string: false,
                icon: <FaBuilding />,
                url: '/suppliers',
                classes: 'nav-item',
                target: false,
                breadcrumbs: false
              }
            ]
          },
          {
            id: 'shippings',
            title: 'Transportadoras',
            type: 'group',
            show: isPermission([
              'ready_shipping',
              'edit_shipping',
              'include_shipping',
              'remove_shipping',
            ]),
            icon: 'feather icon-group',
            children: [
              {
                id: 'shippings',
                title: 'Transportadoras',
                type: 'item',
                show: isPermission([
                  'ready_shipping',
                  'edit_shipping',
                  'include_shipping',
                  'remove_shipping',
                ]),
                icon_string: false,
                icon: <FaShippingFast />,
                url: '/shippings',
                classes: 'nav-item',
                target: false,
                breadcrumbs: false
              }
            ]
          },
          {
            id: 'products',
            title: 'Produtos',
            type: 'group',
            show: isPermission([
              'ready_product',
              'edit_product',
              'include_product',
              'remove_product',
            ]),
            icon: 'feather icon-group',
            children: [
              {
                id: 'products',
                title: 'Produtos',
                type: 'item',
                show: isPermission([
                  'ready_product',
                  'edit_product',
                  'include_product',
                  'remove_product',
                ]),
                icon_string: false,
                icon: <BiPackage />,
                url: '/products',
                classes: 'nav-item',
                target: false,
                breadcrumbs: false
              }
            ]
          },
          {
            id: 'config',
            title: 'Configurações',
            type: 'group',
            icon: 'icon-group',
            show: isPermission([
              'ready_form',
              'edit_form',
              'include_form',
              'remove_form',
              'ready_data_font',
              'edit_data_font',
              'include_data_font',
              'remove_data_font',
              'ready_user',
              'edit_user',
              'include_user',
              'remove_user',
              'ready_profile',
              'edit_profile',
              'include_profile',
              'remove_profile',
            ]),
            children: [
              {
                id: 'form-system',
                title: 'Configurações',
                type: 'collapse',
                icon_string: false,
                icon: <BsFillGearFill />,
                show: isPermission([
                  'ready_form',
                  'edit_form',
                  'include_form',
                  'remove_form',
                  'ready_data_font',
                  'edit_data_font',
                  'include_data_font',
                  'remove_data_font',
                  'ready_user',
                  'edit_user',
                  'include_user',
                  'remove_user',
                  'ready_profile',
                  'edit_profile',
                  'include_profile',
                  'remove_profile',
                ]),
                children: [
                  {
                    id: 'forms-system',
                    title: 'Formulários',
                    type: 'item',
                    url: '/form-system',
                    show: isPermission([
                      'ready_form',
                      'edit_form',
                      'include_form',
                      'remove_form',
                    ]),
                  },
                  {
                    id: 'list-status',
                    title: 'Status',
                    type: 'item',
                    url: '/list-status',
                    show: isPermission('ready_client'),
                  },
                  {
                    id: 'list-fonts',
                    title: 'Fontes de dados',
                    type: 'item',
                    url: '/list-fonts',
                    show: isPermission([
                      'ready_data_font',
                      'edit_data_font',
                      'include_data_font',
                      'remove_data_font',
                    ]),
                  },
                  {
                    id: 'user',
                    title: 'Usuários',
                    type: 'item',
                    url: '/users',
                    show: isPermission([
                      'ready_user',
                      'edit_user',
                      'include_user',
                      'remove_user',
                    ]),
                  },
                  {
                    id: 'list-profiles',
                    title: 'Perfís de usuário',
                    type: 'item',
                    url: '/list-profiles',
                    show: isPermission([
                      'ready_profile',
                      'edit_profile',
                      'include_profile',
                      'remove_profile',
                    ]),
                  },
                  {
                    id: 'company',
                    title: 'Empresa',
                    type: 'item',
                    url: '/company',
                    show: isPermission([
                      'ready_profile',
                      'edit_profile',
                      'include_profile',
                      'remove_profile',
                    ]),
                  },
                  {
                    id: 'comp-whats',
                    title: 'Números de Whatsapp',
                    type: 'item',
                    url: '/comp-whats',
                    show: isPermission([
                      'ready_profile',
                      'edit_profile',
                      'include_profile',
                      'remove_profile',
                    ]),
                  }
                ]
              },
            ]
          },
          {
            id: 'chart-maps',
            title: 'Chart & Maps',
            type: 'group',
            show: true,
            icon: 'icon-charts',
            children: [
              {
                id: 'charts',
                title: 'Dashboards',
                type: 'collapse',
                show: true,
                icon: 'feather icon-pie-chart',
                children: [
                  {
                    id: 'amchartSales',
                    title: 'Vendas',
                    type: 'item',
                    show: true,
                    url: '/charts/amchartSales'
                  },
                  {
                    id: 'amchartSeller',
                    title: 'Vendedor',
                    type: 'item',
                    show: true,
                    url: '/charts/amchartSeller'
                  },
                  {
                    id: 'amchart',
                    title: 'Negociações',
                    type: 'item',
                    show: true,
                    url: '/charts/amchart'
                  },
                  /*{
                    id: 'apex-chart',
                    title: 'Apex Chart',
                    type: 'item',
                    show: true,
                    url: '/charts/apex-chart'
                  },
                  {
                    id: 'chart-js',
                    title: 'Chart JS',
                    type: 'item',
                    show: true,
                    url: '/charts/chart-js'
                  },
                  {
                    id: 'e-chart',
                    title: 'E-Chart',
                    type: 'item',
                    show: true,
                    url: '/charts/e-chart'
                  },
                  {
                    id: 'google',
                    title: 'Google',
                    type: 'item',
                    show: true,
                    url: '/charts/google-chart'
                  },
                  {
                    id: 'highchart',
                    title: 'Highchart',
                    type: 'item',
                    show: true,
                    url: '/charts/highchart'
                  },
                  {
                    id: 'rechart',
                    title: 'Rechart',
                    type: 'item',
                    show: true,
                    url: '/charts/rechart'
                  },
                  {
                    id: 'nvd3',
                    title: 'NVD3',
                    type: 'item',
                    show: true,
                    url: '/charts/nvd3'
                  },
                  {
                    id: 'radial',
                    title: 'Radial',
                    type: 'item',
                    show: true,
                    url: '/charts/radial'
                  }*/
                ]
              },
              /*{
                id: 'landing-page',
                title: 'Landing Page',
                type: 'item',
                show: true,
                icon: 'feather icon-navigation-2',
                url: '/landing',
                classes: 'nav-item',
                target: true,
                breadcrumbs: false
              }*/
            ]
          },
          /*
          
              {
                id: 'maps',
                title: 'Maps',
                type: 'collapse',
                show: true,
                icon: 'feather icon-map',
                children: [
                  {
                    id: 'google-map',
                    title: 'Google',
                    type: 'item',
                    show: true,
                    url: '/maps/google-map'
                  },
                  {
                    id: 'vector-map',
                    title: 'Vector',
                    type: 'item',
                    show: true,
                    url: '/maps/vector-map'
                  }
                ]
              },
          {//
            id: 'navigation',
            title: 'Navigation',
            type: 'group',
            icon: 'icon-navigation',
            show: true,
            children: [
              {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'collapse',
                icon: 'feather icon-home',
                show: true,
                children: [
                  {
                    id: 'default',
                    title: 'Default',
                    type: 'item',
                    show: true,
                    url: '/'
                  },
                  {
                    id: 'e-commerce',
                    title: 'Ecommerce',
                    type: 'item',
                    show: true,
                    url: '/app/dashboard/e-commerce'
                  },
                  {
                    id: 'crm',
                    title: 'CRM',
                    type: 'item',
                    show: true,
                    url: '/app/dashboard/crm'
                  },
                  {
                    id: 'analytics',
                    title: 'Analytics',
                    type: 'item',
                    show: true,
                    url: '/app/dashboard/analytics'
                  },
                  {
                    id: 'crypto',
                    title: 'Crypto',
                    type: 'item',
                    show: true,
                    url: '/app/dashboard/crypto',
                    badge: {
                      title: 'NEW',
                      type: 'label-danger'
                    }
                  },
                  {
                    id: 'project',
                    title: 'Project',
                    type: 'item',
                    show: true,
                    url: '/app/dashboard/project'
                  }
                ]
              },
              {
                id: 'layout',
                title: 'Page Layouts',
                type: 'collapse',
                show: true,
                icon: 'feather icon-layout',
                children: [
                  {
                    id: 'vertical',
                    title: 'Vertical',
                    type: 'collapse',
                    show: true,
                    children: [
                      {
                        id: 'static',
                        title: 'Static',
                        type: 'item',
                        show: true,
                        url: '/layout/vertical/static',
                        target: true
                      },
                      {
                        id: 'fixed',
                        title: 'Fixed',
                        type: 'item',
                        show: true,
                        url: '/layout/vertical/fixed',
                        target: true
                      },
                      {
                        id: 'nav-fixed',
                        title: 'Navbar Fixed',
                        type: 'item',
                        show: true,
                        url: '/layout/vertical/nav-fixed',
                        target: true
                      },
                      {
                        id: 'collapse-menu',
                        title: 'Collapse Menu',
                        type: 'item',
                        show: true,
                        url: '/layout/vertical/collapse-menu',
                        target: true
                      },
                      {
                        id: 'v-rtl',
                        title: 'Vertical RTL',
                        type: 'item',
                        show: true,
                        url: '/layout/vertical/v-rtl',
                        target: true
                      }
                    ]
                  },
                  {
                    id: 'horizontal',
                    title: 'Horizontal',
                    type: 'item',
                    show: true,
                    url: '/layout/horizontal',
                    target: true
                  },
                  {
                    id: 'horizontal-v2',
                    title: 'Horizontal v2',
                    type: 'item',
                    show: true,
                    url: '/layout/horizontal-v2',
                    target: true
                  },
                  {
                    id: 'horizontal-rtl',
                    title: 'Horizontal RTL',
                    type: 'item',
                    show: true,
                    url: '/layout/horizontal-rtl',
                    target: true
                  },
                  {
                    id: 'box',
                    title: 'Box Layout',
                    type: 'item',
                    show: true,
                    url: '/layout/box',
                    target: true
                  },
                  {
                    id: 'light',
                    title: 'Light Layout',
                    type: 'item',
                    show: true,
                    url: '/layout/light',
                    target: true
                  },
                  {
                    id: 'dark',
                    title: 'Dark Layout',
                    type: 'item',
                    show: true,
                    url: '/layout/dark',
                    target: true,
                    badge: {
                      title: 'Hot',
                      type: 'badge-danger'
                    }
                  },
                  {
                    id: 'color-icon',
                    title: 'Color Icon',
                    type: 'item',
                    show: true,
                    url: '/layout/color-icon',
                    target: true
                  }
                ]
              },
              {
                id: 'widgets',
                title: 'Widget',
                type: 'collapse',
                show: true,
                icon: 'feather icon-layers',
                badge: {
                  title: '100+',
                  type: 'badge-info'
                },
                children: [
                  {
                    id: 'widget-statistic',
                    title: 'Statistic',
                    type: 'item',
                    show: true,
                    url: '/app/widgets/widget-statistic'
                  },
                  {
                    id: 'widget-data',
                    title: 'Data',
                    type: 'item',
                    show: true,
                    url: '/app/widgets/widget-data'
                  },
                  {
                    id: 'widget-table',
                    title: 'Table',
                    type: 'item',
                    show: true,
                    url: '/app/widgets/widget-table'
                  },
                  {
                    id: 'widget-user',
                    title: 'User',
                    type: 'item',
                    show: true,
                    url: '/app/widgets/widget-user'
                  },
                  {
                    id: 'widget-chart',
                    title: 'Chart',
                    type: 'item',
                    show: true,
                    url: '/app/widgets/widget-chart'
                  }
                ]
              }
            ]
          },
          {
            id: 'ui-element',
            title: 'UI ELEMENT',
            type: 'group',
            show: true,
            icon: 'icon-ui',
            children: [
              {
                id: 'basic',
                title: 'Basic',
                type: 'collapse',
                show: true,
                icon: 'feather icon-box',
                children: [
                  {
                    id: 'alert',
                    title: 'Alert',
                    type: 'item',
                    show: true,
                    url: '/basic/alert'
                  },
                  {
                    id: 'button',
                    title: 'Button',
                    type: 'item',
                    show: true,
                    url: '/basic/button'
                  },
                  {
                    id: 'badges',
                    title: 'Badges',
                    type: 'item',
                    show: true,
                    url: '/basic/badges'
                  },
                  {
                    id: 'breadcrumb',
                    title: 'Breadcrumb',
                    type: 'item',
                    show: true,
                    url: '/basic/breadcrumb'
                  },
                  {
                    id: 'pagination',
                    title: 'Pagination',
                    type: 'item',
                    show: true,
                    url: '/basic/pagination'
                  },
                  {
                    id: 'cards',
                    title: 'Cards',
                    type: 'item',
                    show: true,
                    url: '/basic/cards'
                  },
                  {
                    id: 'collapse',
                    title: 'Collapse',
                    type: 'item',
                    show: true,
                    url: '/basic/collapse'
                  },
                  {
                    id: 'carousel',
                    title: 'Carousel',
                    type: 'item',
                    show: true,
                    url: '/basic/carousel'
                  },
                  {
                    id: 'grid-system',
                    title: 'Grid System',
                    type: 'item',
                    show: true,
                    url: '/basic/grid-system'
                  },
                  {
                    id: 'progress',
                    title: 'Progress',
                    type: 'item',
                    show: true,
                    url: '/basic/progress'
                  },
                  {
                    id: 'modal',
                    title: 'Modal',
                    type: 'item',
                    show: true,
                    url: '/basic/modal'
                  },
                  {
                    id: 'spinner',
                    title: 'Spinner',
                    type: 'item',
                    show: true,
                    url: '/basic/spinner',
                    badge: {
                      title: 'New',
                      type: 'badge-danger'
                    }
                  },
                  {
                    id: 'tabs-pills',
                    title: 'Tabs & Pills',
                    type: 'item',
                    show: true,
                    url: '/basic/tabs-pills'
                  },
                  {
                    id: 'typography',
                    title: 'Typography',
                    type: 'item',
                    show: true,
                    url: '/basic/typography'
                  },
                  {
                    id: 'tooltip',
                    title: 'Tooltip',
                    type: 'item',
                    show: true,
                    url: '/basic/tooltip'
                  },
                  {
                    id: 'popovers',
                    title: 'Popovers',
                    type: 'item',
                    show: true,
                    url: '/basic/popovers'
                  },
                  {
                    id: 'other',
                    title: 'Other',
                    type: 'item',
                    show: true,
                    url: '/basic/other'
                  }
                ]
              },
              {
                id: 'advance',
                title: 'Advance',
                type: 'collapse',
                show: true,
                icon: 'feather icon-gitlab',
                children: [
                  {
                    id: 'sweet-alert',
                    title: 'Sweet Alert',
                    type: 'item',
                    show: true,
                    url: '/advance/sweet-alert'
                  },
                  {
                    id: 'datepicker',
                    title: 'Datepicker',
                    type: 'item',
                    show: true,
                    url: '/advance/datepicker'
                  },
                  {
                    id: 'task-board',
                    title: 'Task Board',
                    type: 'item',
                    show: true,
                    url: '/advance/task-board'
                  },
                  {
                    id: 'light-box',
                    title: 'Light Box',
                    type: 'item',
                    show: true,
                    url: '/advance/light-box'
                  },
                  {
                    id: 'adv-modal',
                    title: 'Modal',
                    type: 'item',
                    show: true,
                    url: '/advance/adv-modal'
                  },
                  {
                    id: 'notification',
                    title: 'Notification',
                    type: 'item',
                    show: true,
                    url: '/advance/notification'
                  },
                  {
                    id: 'nestable',
                    title: 'Nestable',
                    type: 'item',
                    show: true,
                    url: '/advance/nestable'
                  },
                  {
                    id: 'p-notify',
                    title: 'P-Notify',
                    type: 'item',
                    show: true,
                    url: '/advance/p-notify'
                  },
                  {
                    id: 'rating',
                    title: 'Rating',
                    type: 'item',
                    show: true,
                    url: '/advance/rating'
                  },
                  {
                    id: 'range-slider',
                    title: 'Range Slider',
                    type: 'item',
                    show: true,
                    url: '/advance/range-slider'
                  },
                  {
                    id: 'slider',
                    title: 'Slider',
                    type: 'item',
                    show: true,
                    url: '/advance/slider'
                  },
                  {
                    id: 'syntax-highlighter',
                    title: 'Syntax Highlighter',
                    type: 'item',
                    show: true,
                    url: '/advance/syntax-highlighter'
                  },
                  {
                    id: 'tour',
                    title: 'Tour',
                    type: 'item',
                    show: true,
                    url: '/advance/tour'
                  },
                  {
                    id: 'tree-view',
                    title: 'Tree View',
                    type: 'item',
                    show: true,
                    url: '/advance/tree-view'
                  }
                ]
              }
            ]
          },
          {
            id: 'ui-forms',
            title: 'Forms',
            type: 'group',
            show: true,
            icon: 'icon-group',
            children: [
              {
                id: 'forms',
                title: 'Forms',
                type: 'collapse',
                show: true,
                icon: 'feather icon-file-text',
                children: [
                  {
                    id: 'form-basic',
                    title: 'Form Elements',
                    type: 'item',
                    show: true,
                    url: '/forms/form-basic'
                  },
                  {
                    id: 'form-advance',
                    title: 'Form Advance',
                    type: 'item',
                    show: true,
                    url: '/forms/form-advance'
                  },
                  {
                    id: 'form-validation',
                    title: 'Form Validation',
                    type: 'item',
                    show: true,
                    url: '/forms/form-validation'
                  },
                  {
                    id: 'form-masking',
                    title: 'Form Masking',
                    type: 'item',
                    show: true,
                    url: '/forms/form-masking'
                  },
                  {
                    id: 'form-wizard',
                    title: 'Form Wizard',
                    type: 'item',
                    show: true,
                    url: '/forms/form-wizard'
                  },
                  {
                    id: 'form-picker',
                    title: 'Form Picker',
                    type: 'item',
                    show: true,
                    url: '/forms/form-picker'
                  },
                  {
                    id: 'form-select',
                    title: 'Form Select',
                    type: 'item',
                    show: true,
                    url: '/forms/form-select'
                  }
                ]
              }
            ]
          },
          {
            id: 'table',
            title: 'Table',
            type: 'group',
            show: true,
            icon: 'icon-table',
            children: [
              {
                id: 'tables',
                title: 'Table',
                show: true,
                type: 'collapse',
                icon: 'feather icon-server',
                children: [
                  {
                    id: 'bootstrap',
                    title: 'Bootstrap Table',
                    type: 'item',
                    show: true,
                    url: '/tables/bootstrap'
                  },
                  {
                    id: 'datatable',
                    title: 'React Tables',
                    type: 'collapse',
                    show: true,
                    children: [
                      {
                        id: 'tbl-basic',
                        title: 'Basic',
                        type: 'item',
                        show: true,
                        url: '/table/datatable/tbl-basic'
                      },
                      {
                        id: 'tbl-footer',
                        title: 'Table Footer',
                        type: 'item',
                        show: true,
                        url: '/table/datatable/tbl-footer'
                      },
                      {
                        id: 'tbl-sorting',
                        title: 'Sorting',
                        type: 'item',
                        show: true,
                        url: '/table/datatable/tbl-sorting'
                      },
                      {
                        id: 'tbl-filtering',
                        title: 'Filtering',
                        type: 'item',
                        show: true,
                        url: '/table/datatable/tbl-filtering'
                      },
                      {
                        id: 'tbl-grouping',
                        title: 'Grouping',
                        type: 'item',
                        show: true,
                        url: '/table/datatable/tbl-grouping'
                      },
                      {
                        id: 'tbl-pagination',
                        title: 'Pagination',
                        type: 'item',
                        show: true,
                        url: '/table/datatable/tbl-pagination'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id: 'pages',
            title: 'Pages',
            type: 'group',
            show: true,
            icon: 'icon-pages',
            children: [
              {
                id: 'auth',
                title: 'Authentication',
                type: 'collapse',
                show: true,
                icon: 'feather icon-lock',
                children: [
                  {
                    id: 'signup-1',
                    title: 'Sign up',
                    type: 'item',
                    show: true,
                    url: '/auth/signup-1',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'signup-2',
                    title: 'Sign up v2',
                    type: 'item',
                    show: true,
                    url: '/auth/signup-2',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'signup-3',
                    title: 'Sign up v3',
                    type: 'item',
                    show: true,
                    url: '/auth/signup-3',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'signup-4',
                    title: 'Sign up v4',
                    type: 'item',
                    show: true,
                    url: '/auth/signup-4',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'signup-5',
                    title: 'Sign up v5',
                    type: 'item',
                    show: true,
                    url: '/auth/signup-5',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'signin-1',
                    title: 'Sign in',
                    type: 'item',
                    show: true,
                    url: '/auth/signin-1',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'signin-2',
                    title: 'Sign in v2',
                    type: 'item',
                    show: true,
                    url: '/auth/signin-2',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'signin-3',
                    title: 'Sign in v3',
                    type: 'item',
                    show: true,
                    url: '/auth/signin-3',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'signin-4',
                    title: 'Sign in v4',
                    type: 'item',
                    show: true,
                    url: '/auth/signin-4',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'signin-5',
                    title: 'Sign in v5',
                    type: 'item',
                    show: true,
                    url: '/auth/signin-5',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'reset-password-1',
                    title: 'Reset Password',
                    type: 'item',
                    show: true,
                    url: '/auth/reset-password-1',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'reset-password-2',
                    title: 'Reset Password v2',
                    type: 'item',
                    show: true,
                    url: '/auth/reset-password-2',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'reset-password-3',
                    title: 'Reset Password v3',
                    type: 'item',
                    show: true,
                    url: '/auth/reset-password-3',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'reset-password-4',
                    title: 'Reset Password v4',
                    type: 'item',
                    show: true,
                    url: '/auth/reset-password-4',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'reset-password-5',
                    title: 'Reset Password v5',
                    type: 'item',
                    show: true,
                    url: '/auth/reset-password-5',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'change-password',
                    title: 'Change Password',
                    type: 'item',
                    show: true,
                    url: '/auth/change-password',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'tabs-auth',
                    title: 'Personal Information',
                    type: 'item',
                    show: true,
                    url: '/auth/tabs-auth',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'profile-settings',
                    title: 'Profile Settings',
                    type: 'item',
                    show: true,
                    url: '/auth/profile-settings',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'map-form',
                    title: 'Map Form',
                    type: 'item',
                    show: true,
                    url: '/auth/map-form',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'subscribe',
                    title: 'Subscribe',
                    type: 'item',
                    show: true,
                    url: '/auth/subscribe',
                    target: true,
                    breadcrumbs: false
                  }
                ]
              },
              {
                id: 'maintenance',
                title: 'Maintenance',
                show: true,
                type: 'collapse',
                icon: 'feather icon-sliders',
                children: [
                  {
                    id: 'error',
                    title: 'Error',
                    type: 'item',
                    show: true,
                    url: '/maintenance/error',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'coming-soon',
                    title: 'Coming Soon',
                    type: 'item',
                    show: true,
                    url: '/maintenance/coming-soon',
                    target: true,
                    breadcrumbs: false
                  },
                  {
                    id: 'offline-ui',
                    title: 'Offline UI',
                    type: 'item',
                    show: true,
                    url: '/maintenance/offline-ui',
                    target: true,
                    breadcrumbs: false
                  }
                ]
              }
            ]
          },
          {
            id: 'app',
            title: 'App',
            type: 'group',
            show: true,
            icon: 'icon-app',
            children: [
              {
                id: 'message',
                title: 'Message',
                type: 'item',
                show: true,
                classes: 'nav-item',
                url: '/message',
                icon: 'feather icon-message-circle'
              },
              {
                id: 'task',
                title: 'Task',
                type: 'collapse',
                show: true,
                icon: 'feather icon-clipboard',
                children: [
                  {
                    id: 'task-list',
                    title: 'Task List',
                    type: 'item',
                    show: true,
                    url: '/task/task-list'
                  },
                  {
                    id: 'task-board',
                    title: 'Task Board',
                    type: 'item',
                    show: true,
                    url: '/task/task-board'
                  },
                  {
                    id: 'task-detail',
                    title: 'Task Detail',
                    type: 'item',
                    show: true,
                    url: '/task/task-detail'
                  }
                ]
              },
              {
                id: 'to-dos',
                title: 'Todo',
                type: 'collapse',
                show: true,
                icon: 'feather icon-check-square',
                children: [
                  {
                    id: 'todo',
                    title: 'Todo',
                    type: 'item',
                    show: true,
                    url: '/todo/todo-basic'
                  }
                ]
              },
              {
                id: 'gallery',
                title: 'Gallery',
                type: 'collapse',
                show: true,
                icon: 'feather icon-image',
                children: [
                  {
                    id: 'grid',
                    title: 'Grid',
                    type: 'item',
                    show: true,
                    url: '/gallery/gallery-grid'
                  },
                  {
                    id: 'photo-grid',
                    title: 'Photo Gallery',
                    type: 'item',
                    show: true,
                    url: '/gallery/photo-grid'
                  },
                  {
                    id: 'masonry',
                    title: 'Masonry',
                    type: 'item',
                    show: true,
                    url: '/gallery/gallery-masonry'
                  },
                  {
                    id: 'gallery-advance',
                    title: 'Advance',
                    type: 'item',
                    show: true,
                    url: '/gallery/gallery-advance'
                  }
                ]
              }
            ]
          },
          {
            id: 'extension',
            title: 'Extension',
            type: 'group',
            show: true,
            icon: 'icon-extension',
            children: [
              {
                id: 'editor',
                title: 'Editor',
                type: 'collapse',
                show: true,
                icon: 'feather icon-file-plus',
                children: [
                  {
                    id: 'ck-editor',
                    title: 'CK-Editor',
                    type: 'collapse',
                    show: true,
                    children: [
                      {
                        id: 'ck-classic',
                        title: 'Classic Editor',
                        type: 'item',
                        show: true,
                        url: '/editor/ck-editor/ck-classic',
                        target: true
                      },
                      {
                        id: 'ck-balloon',
                        title: 'Balloon Editor',
                        type: 'item',
                        show: true,
                        url: '/editor/ck-editor/ck-balloon',
                        target: true
                      },
                      {
                        id: 'ck-inline',
                        title: 'Inline Editor',
                        type: 'item',
                        show: true,
                        url: '/editor/ck-editor/ck-inline',
                        target: true
                      },
                      {
                        id: 'ck-document',
                        title: 'Document Editor',
                        type: 'item',
                        show: true,
                        url: '/editor/ck-editor/ck-document',
                        target: true
                      }
                    ]
                  },
                  {
                    id: 'rich-editor',
                    title: 'Rich Editor',
                    type: 'item',
                    show: true,
                    url: '/editor/rich-editor'
                  },
                  {
                    id: 'jodit-wysiwyg',
                    title: 'Jodit WYSIWYG',
                    type: 'item',
                    show: true,
                    url: '/editor/jodit-wysiwyg'
                  }
                ]
              },
              {
                id: 'invoice',
                title: 'Invoice',
                type: 'collapse',
                show: true,
                icon: 'feather icon-file-minus',
                children: [
                  {
                    id: 'invoice-basic',
                    title: 'Invoice Basic',
                    type: 'item',
                    show: true,
                    url: '/invoice/invoice-basic'
                  },
                  {
                    id: 'invoice-summary',
                    title: 'Invoice Summary',
                    type: 'item',
                    show: true,
                    url: '/invoice/invoice-summary'
                  },
                  {
                    id: 'invoice-list',
                    title: 'Invoice List',
                    type: 'item',
                    show: true,
                    url: '/invoice/invoice-list'
                  }
                ]
              },
              {
                id: 'full-calendar',
                title: 'Full Calendar',
                type: 'item',
                show: true,
                url: '/full-calendar',
                classes: 'nav-item',
                icon: 'feather icon-calendar'
              },
              {
                id: 'file-upload',
                title: 'File Upload',
                type: 'item',
                show: true,
                url: '/file-upload',
                classes: 'nav-item',
                icon: 'feather icon-upload-cloud'
              },
              {
                id: 'image-cropper',
                title: 'Image Cropper',
                type: 'item',
                show: true,
                url: '/image-cropper',
                classes: 'nav-item',
                icon: 'feather icon-image'
              }
            ]
          },
          {
            id: 'support',
            title: 'Support',
            show: true,
            type: 'group',
            icon: 'icon-support',
            children: [
              {
                id: 'menu-level',
                title: 'Menu Levels',
                type: 'collapse',
                show: true,
                icon: 'feather icon-menu',
                children: [
                  {
                    id: 'menu-level-1.1',
                    title: 'Menu Level 1.1',
                    type: 'item',
                    show: true,
                    url: '#!'
                  },
                  {
                    id: 'menu-level-1.2',
                    title: 'Menu Level 2.2',
                    type: 'collapse',
                    show: true,
                    children: [
                      {
                        id: 'menu-level-2.1',
                        title: 'Menu Level 2.1',
                        type: 'item',
                        show: true,
                        url: '#'
                      },
                      {
                        id: 'menu-level-2.2',
                        title: 'Menu Level 2.2',
                        type: 'collapse',
                        show: true,
                        children: [
                          {
                            id: 'menu-level-3.1',
                            title: 'Menu Level 3.1',
                            type: 'item',
                            show: true,
                            url: '#'
                          },
                          {
                            id: 'menu-level-3.2',
                            title: 'Menu Level 3.2',
                            type: 'item',
                            show: true,
                            url: '#'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                id: 'disabled-menu',
                title: 'Disabled Menu',
                type: 'item',
                show: true,
                url: '#',
                classes: 'nav-item disabled',
                icon: 'feather icon-power'
              },
              {
                id: 'sample-page',
                title: 'Sample Page',
                type: 'item',
                show: true,
                url: '/sample-page',
                classes: 'nav-item',
                icon: 'feather icon-sidebar'
              },
              {
                id: 'documentation',
                title: 'Documentation',
                type: 'item',
                show: true,
                icon: 'feather icon-book',
                classes: 'nav-item',
                url: 'https://html.codedthemes.com/datta-able/react/docs',
                target: true,
                external: true
              }
            ]
          }, */ //
        ]
      }
    )
  }, [permissions, isPermission])


  return {menuItems}
}

export default MenuItems;
